import PropTypes from "prop-types"
import React from "react"
import cn from 'classnames'
import MenuToolbar from 'components/MenuToolbar'
import Hero from './Hero'

import theme from './header.module.scss'

const Header = ({ className, featured }) => {
  console.log(featured)
  return (
    <header className={cn(theme.Header, className)}>
      <MenuToolbar />
      <Hero featuredItem={featured} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
