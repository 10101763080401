import React from 'react'
import theme from './Footer.module.scss'
import SocialLinks from './SocialLinks'

export default () => (
  <footer className={theme.Footer}>
    <SocialLinks />
    <div className={theme.Footer_Copyright}>
      © {new Date().getFullYear()}, The Startup Lab, LLC
    </div>
  </footer>
)