import React from 'react'
import { Link } from 'gatsby'
import theme from './RecentPost.module.scss'

const RecentPost = () => (
 <div className={theme.RecentPost_Container}>
  <div>
   <h4>Popular Videos</h4>
   <ul>
    <li>
     <a href="/video/angular-vs-react">Angular VS React</a>
    </li>
    <li>
     <a href="/video/what-are-web-components">What are web components?</a>
    </li>
    <li>
     <a href="/video/what-is-unit-e2e-testing">Automated Testing</a>
    </li>
   </ul>
  </div>

  <div style={{ marginTop: '35px' }}>
   <h4>Popular Articles</h4>
   <ul>
    <li>
     <Link to="/blog/creating-angularjs-components/">AngularJS Components</Link>
    </li>
    <li>
     <Link to="blog/mobileweb-vs-mobileapp">Mobile Web vs Mobile App</Link>
    </li>

   </ul>
  </div>
 </div>
)

export default RecentPost
