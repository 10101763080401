import React from 'react'
import PropTypes from 'prop-types'
import theme from './Toolbar.module.scss'

const Toolbar = ({ itemLeft, itemRight, className }) => (
  <div className={theme.Toolbar}>
    <div className={theme.Toolbar_InnerContainer}>
      {itemLeft}
      {itemRight}
    </div>
  </div>
)

Toolbar.propTypes = {
  itemLeft: PropTypes.node,
  itemRight: PropTypes.node,
}

export default Toolbar
