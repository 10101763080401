import React from 'react'
import theme from './SocialLinks.module.scss'
import GITHUB from 'images/social/github.png'
import INSTAGRAM from 'images/social/instagram.png'
import TWITTER from 'images/social/twitter.png'
import FACEBOOK from 'images/social/facebook.png'
import YOUTUBE from 'images/social/youtube.png'
import FOOTER_LOGO from 'images/logo/logo-horizontal-white.png'

export default () => (
  <div className={theme.SocialLinks}>
    <img className={theme.FooterLogo} src={FOOTER_LOGO} />
    <div className={theme.SocialLinks_Container}>
      <a href='https://github.com/TheStartupLab' target="blank">
        <img src={GITHUB} />
      </a>
      <a href='https://www.instagram.com/thestartuplab/' target="blank">
        <img src={INSTAGRAM} />
      </a>
      <a href='https://twitter.com/TheStartupLabTX' target="blank">
        <img src={TWITTER} />
      </a>
      <a href='https://facebook.com/TheStartupLabTX' target="blank">
        <img src={FACEBOOK} />
      </a>
      <a href='https://www.youtube.com/channel/UCEV3CWn4g_gYkcpQTjFQvbg' target="blank">
        <img src={YOUTUBE} />
      </a>
    </div>
    <p className='center-text'>3740 Greenbriar Dr #540772 <br /> Houston, TX 77098 </p>
  </div>
)