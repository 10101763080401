// import PropTypes from "prop-types"
import React from "react"
// import Dropdown from 'components/ui/Dropdown'
import theme from './menu.module.scss'
import cn from 'classnames'
import themeUtils from 'styles/utils.module.scss'
import { Link } from 'gatsby'
import Sidenav from '../Sidenav'

const menuItems = [
  {
    title: 'Written Tutorials',
    url: '/blog'
  },
  {
    title: 'Videos',
    url: '/video'
  },
  {
    title: 'About',
    url: '/about'
  }
]

const Menu = ({ siteTitle }) => (
  <>
    <div className={cn(theme.Menu, themeUtils.hideXS)}>
      {/*
   <Dropdown menuItems={[
     { title: 'Web', href: '/lessons/web' },
     { title: 'Mobile', href: '/lessons/mobile' },
     { title: 'IoT', href: '/lessons/iot' },
    ]} primaryButton={{ title: 'Lessons', href: '/lessons' }}
   />
  */}

      {/*<Link to='/lessons'>
   <span className={theme.Menu_Item}>
    Lessons
   </span>
  </Link>*/}
      <Link to='/blog'>
        <span className={theme.Menu_Item}>
          Written Tutorials
   </span>
      </Link>
      <Link to='/video'>
        <span className={theme.Menu_Item}>
          Video Lessons
        </span>
      </Link>
      <Link to='/about'>
        <span className={theme.Menu_Item}>
          About
        </span>
      </Link>
    </div>
    <Sidenav
      buttonStyle={cn(
        themeUtils.hideMD,
        themeUtils.hideDesktop,
        theme.MenuToggleButton,
      )}
      menuItems={menuItems}
    />
  </>
)

export default Menu
