import React from 'react'
import theme from './hero.module.scss'
import { Link } from 'gatsby'
import urlManager from 'lib/utils/urlManager'

const Hero = ({ featuredItem }) => {
  const feature = featuredItem.node.frontmatter
  const { type, title, page } = feature
  const pageLink = urlManager.buildSEOpathForContent(type, title, page)

  return (
    <div className={theme.Hero}>
      <div className={theme.Hero_Headline}>
        <h1>The Startup Lab</h1>
        <p>Practical Web Tutorials For Growing Teams</p>
      </div>
      <Link to={pageLink}>
        <div className={theme.Hero_Container}>
          <div className={theme.Hero_FeaturedContent}>
            <img src={feature.imageUrl.childImageSharp.fixed.src} />
            <div className={theme.Hero_Title}>
              <h4>{feature.title}</h4>
            </div>
          </div>
          {/**<div className={theme.Hero_AsideContent}></div> */}
        </div>
      </Link>
    </div >
  )
}

export default Hero
