import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import ArticleFeedUI from './ArticleFeedUI'
import AllArticleTypesQuery from 'graphql/AllArticleTypesQuery'


const ArticleFeed = () => (
  <StaticQuery
    query={graphql`
      query ArticleFeedQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          ) {
          edges {
            node {
              excerpt(pruneLength: 250)
              id
              frontmatter {
                title
                type
                page
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={data => <ArticleFeedUI data={data} />} />
)

export default ArticleFeed
