export const SOFTWARE_DEVELOPER = 'gpt_software_developer'
export const BLOGLIST_BOTTOM_AD1 = 'gpt_bloglist_ad1'

const ADS_DISABLED = false

const defaults = {
 mute: false,
 network: '21805658526',
}
const ads = {
 [SOFTWARE_DEVELOPER]: {
  ...defaults,
  adUnit: 'dfp_software_developer',
  sizes: [[250, 250], 'fluid']
 },
 [BLOGLIST_BOTTOM_AD1]: {
  ...defaults,
  adUnit: 'dfp_blog_list_btf1',
  sizes: [[250, 250]]
 },
}

export default {
 ads,
 isDisabled: () => (
  ADS_DISABLED || false
 )
}