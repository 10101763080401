import React from 'react'
import Toolbar from 'components/ui/Toolbar'
import Logo from 'components/ui/Logo'
import Menu from './Menu'
import { Link } from "gatsby"
import theme from './MenuToolbar.module.scss'

const MenuToolbar = ({ shouldDisplaySubMeny }) => (
 <>
  <Toolbar
   itemLeft={
    <Link to='/'>
     <Logo />
    </Link>
   }
   itemRight={<Menu />}
  />
  {false && (
   <div className={theme.SubMenu}>
    <ul>
     <li>#Web</li>
     <li>#Mobile</li>
     <li>#IoT</li>
    </ul>
   </div>
  )}
 </>
)

export default MenuToolbar