import React from "react"
import cn from 'classnames'
import { BrowserView } from 'react-device-detect'
import { graphql } from "gatsby"
import { SOFTWARE_DEVELOPER } from 'config/dfpAds'
import SEO from "components/seo"
import Header from "components/Header"
import Layout from "components/layout"
import DfpAdSlot from 'components/DfpAdSlot'
import RecentPost from 'components/ui/RecentPost'
import ArticleFeed from 'components/ArticleFeed'
import themeUtils from 'styles/utils.module.scss'
import theme from './home.module.scss'


const IndexPage = ({ data }) => {
  const { articles } = data
  const { edges: posts } = articles
  const featured = posts
  return (
    <>
      <SEO title="Home" keywords={[`the startup lab`, `learn web development`, `react`]} />
      <Header featured={featured[4]} className={theme.section} />
      <Layout className={theme.Home}>
        <BrowserView viewClassName={cn(themeUtils.hideXS, theme.Blog_GutterLeft)}>
          <RecentPost />
          <DfpAdSlot className={theme.Blog_Ad_sticky} adKey={SOFTWARE_DEVELOPER} />
        </BrowserView>
        <ArticleFeed />
      </Layout >
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    articles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            type
            date(formatString: "MMMM DD, YYYY")
            imageUrl {
              childImageSharp {
                fixed(width: 650, height: 350) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
