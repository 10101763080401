import React from 'react'
import { Link } from 'gatsby'
import theme from './ArticleFeed.module.scss'
import urlManager from 'lib/utils/urlManager'

const ArticleFeedUI = ({ data }) => (
 <div className={theme.Post_Container}>
  {data.allMarkdownRemark.edges
   .filter(post => post.node.frontmatter.title.length > 0)
   .map(({ node: post }) => {
    const { title = 'default', type, page } = post.frontmatter
    const urlLink = urlManager.buildSEOpathForContent(type, title, page)

    return (
     <div className={theme.Post_Preview} key={post.id}>
      <h1>
       <Link to={urlLink}>{post.frontmatter.title}</Link>
      </h1>
      <h2>{post.frontmatter.date}</h2>
      <p>{post.excerpt}</p>
     </div>
    )
   })}
 </div>
)

export default ArticleFeedUI