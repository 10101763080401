import React, { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import theme from './Sidenav.module.scss'
import { FiMenu as MenuIcon } from 'react-icons/fi';

class Sidenav extends PureComponent {
 constructor() {
  super()
  this.state = {
   isOpen: false,
  }
 }

 static propTypes = {
  menuItems: PropTypes.array,
 }

 toggleSidenav = () => {
  this.setState(prevState => ({
   isOpen: !prevState.isOpen
  }))
 }

 renderMenuItems() {
  const { menuItems } = this.props
  if (!menuItems.length) return null

  return (
   menuItems.map((i, idx) => (
    <a href={i.url} key={idx}>
     <li>{i.title}</li>
    </a>
   ))
  )
 }
 render() {
  const { isOpen } = this.state
  const {
   buttonStyle,
   containerStyle
  } = this.props

  return (
   <>
    <button className={buttonStyle} onClick={this.toggleSidenav}>
     <MenuIcon />
    </button>
    <div className={
     cn(
      theme.Sidenav,
      { [theme.Sidenav_isOpen]: isOpen },
      containerStyle,
     )
    }>
     <div style={{ padding: '5px' }}>
      <button onClick={this.toggleSidenav}>X</button>
     </div>
     <ul>
      {this.renderMenuItems()}
     </ul>
    </div>
   </>
  )
 }
}

export default Sidenav