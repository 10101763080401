const buildSEOpathForContent = (type, title, page = '1') => {
 const deliminitedTitle = title.toLowerCase().replace(/\s/g, '-')
 const seoTitle = deliminitedTitle.replace(/[?]/, "");
 const rootPath = `${type}/${seoTitle}`
 const pagePath = page === "1" ? rootPath : `${rootPath}/${page}`

 return pagePath
}

module.exports = {
 buildSEOpathForContent
}