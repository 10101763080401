import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp'
import cn from 'classnames'
import DfpAds from 'config/dfpAds'
import theme from './DfpAdSlot.module.scss'

export default class DfpAdSlot extends PureComponent {
 static propTypes = {
  customTargeting: PropTypes.object,
  doNotRefresh: PropTypes.bool,
  onSlotRender: PropTypes.func,
  className: PropTypes.string,
  targetCode: PropTypes.string,
  bypass: PropTypes.bool,
 }

 static defaultProps = {
  customTargeting: null,
  doNotRefresh: false,
  bypass: false,
 }

 constructor() {
  super()
  this.state = {
   hidden: false,
  }
 }

 get ads() {
  return DfpAds.ads || {}
 }

 get isDisabled() {
  return DfpAds.isDisabled()
 }

 get slotRenderCallback() {
  const { onSlotRender, adKey } = this.props
  // const { setDisplayReady } = this.context.dfp || {}

  return (gpt = { event: {} }) => {
   // googletag fires slot render event even if ad is empty,
   // so explicit check is needed here
   if (!gpt.event.isEmpty) {
    this.setState({ hidden: false })
    if (onSlotRender) onSlotRender(adKey)
    // if (setDisplayReady) setDisplayReady()
   } else {
    this.setState({ hidden: true })
   }
  }
 }

 render() {
  const {
   adKey,
   doNotRefresh,
   className,
   bypass,
  } = this.props
  const { hidden } = this.state

  if (bypass || !this.ads[adKey]) return null

  const {
   sizes,
   network,
   adUnit,
   mute,
  } = this.ads[adKey]

  if (mute || this.isDisabled) return null

  return (
   <div
    className={cn({
     [theme.Dfp]: true,
     [theme.Dfp_ready]: true,
     [className]: className,
    })}
   >
    <DFPSlotsProvider dfpNetworkId={network} collapseEmptyDivs>
     <AdSlot
      sizes={sizes}
      adUnit={adUnit}
      shouldRefresh={() => !doNotRefresh}
      onSlotRender={this.slotRenderCallback}
     />
    </DFPSlotsProvider>
   </div>
  )
 }
}